import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const PrivacyPageTemplate = ({ title, content }) => {
  const PageContent = HTMLContent || Content;

  return (
    <div>
      <h2 className="font-poppins font-semibold text-3xl px-4 md:px-16 lg:px-80 pb-4 md:pb-8">
        {title}
      </h2>
      <PageContent className="markdown" content={content} />
    </div>
  );
};

PrivacyPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const PrivacyPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PrivacyPageTemplate title={post.frontmatter.title} content={post.html} />
    </Layout>
  );
};

PrivacyPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PrivacyPage;

export const privacyPageQuery = graphql`
  query PrivacyPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
